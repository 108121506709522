import React from 'react';
import './App.css';
import { Container, Typography, Box } from '@mui/material';
import GoogleMapComponent from './GoogleMapComponent.js';


function App() {
  return (
    <Container className="App" >
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          FedEx Route Planning
        </Typography>
        <GoogleMapComponent />
      </Box>
    </Container>
  );
}

export default App;
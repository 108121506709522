import React, { useState, useCallback, useRef, useEffect } from "react";
import { StandaloneSearchBox } from "@react-google-maps/api";
import {
  TextField,
  Button,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import axios from "axios";

const containerStyle = {
  width: "100%",
  height: "80vh",
};

const center = {
  lat: 38.99924386005628,
  lng: -77.35159559355101,
};

const GoogleMapComponent = () => {
  const [selectedDay, setSelectedDay] = useState("monday");
  const [map, setMap] = useState(null);
  const [searchedLocationMarker, setSearchedLocationMarker] = useState(null);
  const anchorAreasRef = useRef([]);
  const labelsRef = useRef([]);
  const searchBoxRef = useRef(null);
  const inputRef = useRef(null);
  const [isMapsApiLoaded, setIsMapsApiLoaded] = useState(false);
  const [workAreaInfo, setWorkAreaInfo] = useState("No Work Area Found");

  // Track if the map has been initialized with bounds fitting
  const hasInitializedRef = useRef(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (window.google && window.google.maps) {
        setIsMapsApiLoaded(true);
        clearInterval(interval);
      }
    }, 100);
    return () => clearInterval(interval);
  }, []);

  const fetchWorkAreaData = async (day) => {
    try {
      const response = await axios.get(
        "http://3.92.181.201/api/advancedvehiclesets",
        {
          params: { day },
        }
      );
      return response.data.workAreas || [];
    } catch (error) {
      console.error("Error fetching work area data:", error);
      return [];
    }
  };

  const fetchRoutePlanData = async (day) => {
    try {
      const response = await axios.get("http://3.92.181.201/api/routeplans", {
        params: { day },
      });
      return response.data.anchorAreas || [];
    } catch (error) {
      console.error("Error fetching route plan data:", error);
      return [];
    }
  };

  const fitMapToBounds = useCallback(() => {
    if (anchorAreasRef.current.length > 0 && map && !hasInitializedRef.current) {
      const bounds = new window.google.maps.LatLngBounds();
      anchorAreasRef.current.forEach((polygon) => {
        polygon.getPath().forEach((point) => bounds.extend(point));
      });
      map.fitBounds(bounds);
      hasInitializedRef.current = true; // Mark as initialized to prevent further zoom adjustments
    }
  }, [map]);

  useEffect(() => {
    const loadAnchorAreas = async () => {
      const workAreaData = await fetchWorkAreaData(selectedDay);
      const routePlanData = await fetchRoutePlanData(selectedDay);

      if (map) {
        anchorAreasRef.current.forEach((polygon) => polygon.setMap(null));
        anchorAreasRef.current = [];

        if (labelsRef.current.length === 0) {
          const newLabels = [];
          workAreaData.forEach((workArea) => {
            const color = workArea.color || "#FF0000";

            workArea.anchorAreas.forEach((anchorArea) => {
              const routePlanAnchor = routePlanData.find(
                (r) => r.anchorAreaId === anchorArea.anchorAreaId
              );

              if (routePlanAnchor && routePlanAnchor.shape) {
                const coordinates = routePlanAnchor.shape.map((coord) => ({
                  lat: coord[0],
                  lng: coord[1],
                }));

                const polygon = new window.google.maps.Polygon({
                  paths: coordinates,
                  fillColor: color,
                  fillOpacity: 0.35,
                  strokeColor: 'black',
                  strokeOpacity: 1,
                  strokeWeight: 1,
                });

                polygon.setMap(map);
                anchorAreasRef.current.push(polygon);

                const bounds = new window.google.maps.LatLngBounds();
                coordinates.forEach((point) => bounds.extend(point));
                const center = bounds.getCenter();

                const label = new window.google.maps.Marker({
                  position: center,
                  map,
                  icon: {
                    path: window.google.maps.SymbolPath.CIRCLE,
                    scale: 0, // Invisible marker
                  },
                  label: {
                    text: `${Number(workArea.workAreaNumber).toString()}`,
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "10px",
                    className: "custom-label",
                  },
                  workAreaName: workArea.workAreaName,
                  workAreaNumber: workArea.workAreaNumber,
                });

                newLabels.push(label);
              }
            });
          });

          labelsRef.current = newLabels;
        }

        // Fit the map to the bounds of the polygons
        fitMapToBounds();
      }
    };

    if (isMapsApiLoaded) {
      loadAnchorAreas();
    }
  }, [selectedDay, map, isMapsApiLoaded, fitMapToBounds]);

  const onLoad = useCallback((mapInstance) => {
    setMap(mapInstance);

    // Immediately fit bounds after map instance is set
    fitMapToBounds();
  }, [fitMapToBounds]);

  const onPlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces();
    if (places && places.length > 0) {
      const place = places[0];
      if (place.geometry) {
        const location = place.geometry.location;
        map.panTo(location);
        map.setZoom(15);

        // Create or update marker
        if (searchedLocationMarker) {
          searchedLocationMarker.setPosition(location);
        } else {
          const marker = new window.google.maps.Marker({
            position: location,
            map,
          });
          setSearchedLocationMarker(marker);
        }

        let foundWorkArea = false;

        anchorAreasRef.current.forEach((polygon, index) => {
          if (
            window.google.maps.geometry.poly.containsLocation(location, polygon)
          ) {
            const label = labelsRef.current[index];
            if (label) {
              setWorkAreaInfo(
                `${label.workAreaName} - ${Number(
                  label.workAreaNumber
                ).toString()}`
              );
              foundWorkArea = true;
            }
          }
        });

        if (!foundWorkArea) {
          setWorkAreaInfo("No Work Area Found");
        }
      }
    }
  };

  const style = document.createElement("style");
  style.type = "text/css";
  style.innerHTML = `
    .custom-label {
      background: black;
      border-radius: 5px;
      padding: 2px 4px;
      white-space: nowrap;
      text-align: center;
    }
  `;
  document.getElementsByTagName("head")[0].appendChild(style);

  if (!isMapsApiLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <Box
      className="mobile-layout"
      display="flex"
      flexDirection="column"
      height="75vh"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{ gap: 2 }}
      >
        {/* First row: Day selection */}
        <FormControl
          variant="outlined"
          size="small"
          sx={{ width: "400px" }} // Fixed width for day selection
        >
          <InputLabel htmlFor="day-select">Day</InputLabel>
          <Select
            id="day-select"
            name="day"
            value={selectedDay}
            onChange={(e) => {
              setSelectedDay(e.target.value);
              labelsRef.current.forEach((label) => label.setMap(null));
              labelsRef.current = [];
            }}
            label="Day"
          >
            <MenuItem value="monday">Monday</MenuItem>
            <MenuItem value="tuesday">Tuesday</MenuItem>
            <MenuItem value="wednesday">Wednesday</MenuItem>
            <MenuItem value="thursday">Thursday</MenuItem>
            <MenuItem value="friday">Friday</MenuItem>
            <MenuItem value="saturday">Saturday</MenuItem>
            <MenuItem value="sunday">Sunday</MenuItem>
          </Select>
        </FormControl>

        {/* Second row: Search bar and delete button */}
        <Box
          display="flex"
          alignItems="center"
          sx={{ width: "400px", gap: 1 }} // Fixed width for combined search bar and delete button
        >
          <StandaloneSearchBox
            onLoad={(ref) => (searchBoxRef.current = ref)}
            onPlacesChanged={onPlacesChanged}
          >
            <TextField
              id="search-field"
              inputRef={inputRef}
              name="search"
              placeholder="Search for an address"
              variant="outlined"
              size="small"
              fullWidth
              sx={{
                background: "white",
                borderRadius: "5px",
                width: "328px", // Adjust width to maintain the overall width
              }}
            />
          </StandaloneSearchBox>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#660099",
              "&:hover": { backgroundColor: "#9100D9" },
              height: "40px",
              width: "50px", // Button width to ensure the total width matches other rows
            }}
            onClick={() => {
              setWorkAreaInfo("No Work Area Found");
              if (searchedLocationMarker) {
                searchedLocationMarker.setMap(null);
                setSearchedLocationMarker(null);
              }
              if (inputRef.current) {
                inputRef.current.value = "";
              }
            }}
          >
            X
          </Button>
        </Box>

        {/* Third row: Work area info */}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ width: "400px" }} // Fixed width for work area info, with margin-top for spacing
        >
          <TextField
            value={workAreaInfo}
            variant="outlined"
            size="small"
            fullWidth
            InputProps={{
              readOnly: true,
              sx: {
                fontWeight: "bold", // Make the text bold
                textAlign: "center", // Center the text horizontally
              },
            }}
          />
        </Box>
      </Box>

      <div
        style={{ ...containerStyle, marginTop: "16px" }} // Add margin-top to create gap between info text and map
        ref={(mapRef) => {
          if (mapRef && !map) {
            const mapInstance = new window.google.maps.Map(mapRef, {
              center,
              zoom: 10,
            });
            onLoad(mapInstance);
          }
        }}
      />
    </Box>
  );
};

export default GoogleMapComponent;
